.embed-video-container {
  display: flex;
  flex-direction: column;
}
.embed-video-container h2 {
  order: -1;
}
.embed-youtube,
.embed-youtube .embed-youtube-play,
.embed-youtube img {
  cursor: pointer;
}
.embed-youtube {
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}
.embed-youtube img {
  width: 100%;
  top: -16.84%;
  left: 0;
  opacity: 0.7;
}
.embed-youtube .embed-youtube-play {
  width: 68px;
  height: 48px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
}
.embed-youtube .embed-youtube-play:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26px;
  border-color: transparent transparent transparent #fff;
}
.embed-youtube .embed-youtube-play,
.embed-youtube .embed-youtube-play:before,
.embed-youtube iframe,
.embed-youtube img {
  position: absolute;
}
.embed-youtube .embed-youtube-play,
.embed-youtube .embed-youtube-play:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.embed-youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.embed-youtube .embed-youtube-play:hover {
  background-color: red;
}

btn-internal {
  line-height: 1.42;
  text-decoration: none;
  text-align: center;
  white-space: normal;
  font-size: calc(var(--typeBaseSize) - 4px);
  font-size: max(calc(var(--typeBaseSize) - 4px), 13px);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  display: inline-block;
  padding: 11px 20px;
  margin: 0;
  width: auto;
  min-width: 90px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: var(--buttonRadius);
  color: #fff;
  background: #c20000 !important;
}

#shopify-block-klarna_on_site_messaging_app_block_JfKx6W {
  margin-top: -20px !important;
}